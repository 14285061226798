<template>
  <v-card>
    <v-card-title class="text-h6 mb-3">
      Hàng hóa không xuất hóa đơn
      <v-spacer></v-spacer>
      <v-btn color="red darken-1" text @click="close">
        {{ $t("labels.cancel") }}
      </v-btn>
      <v-btn
        color="green darken-1"
        text
        @click="confirm"
        :disabled="isDisabledBtn"
      >
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table
        height="calc(100vh - 400px)"
        class="table-padding-2-no-top table-h-36"
        fixed-header
      >
        <template v-slot:default>
          <tbody>
            <tr>
              <td colspan="9">
                <SelectSuggestGoodsNoWarehouse
                  @onSelect="selectItem"
                  :select-items="items"
                  :default-filters="{ is_invoice: 1 }"
                />
              </td>
            </tr>
            <tr class="font-weight-medium text-center">
              <td style="width: 100px">{{ $t("labels.image") }}</td>
              <td>{{ $t("labels.sku") }}</td>
              <td>{{ $t("labels.barcode") }}</td>
              <td>{{ $t("labels.category") }}</td>
              <td>{{ $t("labels.name") }}</td>
              <td>{{ $t("labels.goods_description") }}</td>
              <td>{{ $t("labels.size") }}</td>
              <td class="text-center" style="width: 75px">
                <v-btn
                  v-if="items.length > 0"
                  color="purple"
                  class="white--text"
                  x-small
                  @click="clearItem()"
                >
                  {{ $t("labels.clear") }}
                </v-btn>
              </td>
            </tr>
            <tr
              class="text-center"
              v-for="(cItem, cIndex) in items"
              :key="`c_${cIndex}_${cItem.id}`"
            >
              <td>
                <ImageViewer
                  v-if="cItem.url_images"
                  :url="cItem.url_images"
                  width="40px"
                  height="40px"
                />
              </td>
              <td>{{ cItem.sku }}</td>
              <td>{{ cItem.customer_goods_barcode }}</td>
              <td>{{ cItem.category_name }}</td>
              <td>{{ cItem.name }}</td>
              <td>{{ cItem.description }}</td>
              <td>{{ cItem.size }}</td>
              <td>
                <v-btn
                  x-small
                  color="error"
                  @click="removeItem(cItem)"
                  class="mb-1"
                >
                  {{ $t("labels.delete") }}
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "GoodsNoInvoiceCreate",
  components: {
    SelectSuggestGoodsNoWarehouse: () =>
      import("@/components/common/SelectSuggestGoodsNoWarehouse"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {},
  data: () => ({
    items: [],
    isLoading: false,
  }),
  computed: {
    isDisabledBtn() {
      if (!this.items || this.items.length === 0) {
        return true;
      }

      return false;
    },
  },
  mounted() {},
  methods: {
    selectItem(item) {
      this.items.push({
        ...item,
      });
    },

    removeItem(item) {
      const items = [...this.items].filter((it) => it.id !== item.id);
      this.items = [...items];
    },

    clearItem() {
      const items = [];
      this.items = [...items];
    },

    close() {
      this.$emit("close");
    },

    async confirm() {
      if (this.isDisabledBtn) {
        return false;
      }

      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/invoice-goods-no-export-save", {
          ids: [...this.items].map((item) => item.id),
          is_invoice: 0,
        });
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.$emit("refreshData");
        this.close();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped></style>
